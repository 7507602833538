import React, { useState } from 'react';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { LockerInfo } from 'components/common/LockerInfo';
import { colors } from 'config/colors';
import api from 'helpers/api';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomToast from 'components/common/customToast';
import { useEffect } from 'react';
import { changeLanguage } from 'redux/actions/language';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

export const Deposit = ({ step, setStep, objCode, setObjCode, phoneNumber, setPhoneNumber, deltaRef, setDeltaRef,setStaffCode,staffCode }) => {

  const [locker, setLocker] = useState({});
  const intl = useIntl();
  const [showToast, setShowToast] = useState(false);
  const [availableSizes, setAvailableSizes] = useState(false);
  const [lockerSize, setLockerSize] = useState(false)
  const dispatch = useDispatch()
  const recaptchaRef = React.createRef();

  const createOrder = () => {
    recaptchaRef.current.reset()
    recaptchaRef.current.executeAsync().then((token) => {
      let my_captcha_response = window.grecaptcha.getResponse()
      if (my_captcha_response) {
        const formData = new FormData();
        formData.append('objCode', objCode);
        formData.append('phoneNumber', phoneNumber);
        formData.append('staffCode', staffCode);
        if (deltaRef !== '' && deltaRef) {
          formData.append('ref', deltaRef);
        }
        api.post('api/partner/orders/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
          }
        })
          .then(function (response) {
            console.log(response);
            if (response.data.ResultCode === 1) {
              setShowToast(false)
              getLockersSize()
            }
            else {
              setShowToast(true)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      else{
        setShowToast(true)
      }
    })
  };

  const getLockersSize = () => {
    api.get(`api/partner/lockers/availableSizes/${process.env.REACT_APP_LOCATION_ID}`)
      .then(function (response) {
        if (response.data.ResultCode === 1) {
          setAvailableSizes(response.data.Data);
          setStep(3);
          setShowToast(false)
        }
        else {
          setShowToast(true)
          setAvailableSizes(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const chooseLockerSize = (size) => {
    setLockerSize(size)
    const formData = new FormData();
    formData.append('objCode', objCode);
    formData.append('size', size);
    formData.append('locationId', process.env.REACT_APP_LOCATION_ID);
    api.post(`api/partner/orders/assignToOrder`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
      }
    })
      .then(function (response) {
        console.log(response);
        if (response.data.ResultCode === 1) {
          setStep(4);
          setLocker(response.data.Data.assignedLocker);
          setShowToast(false)
        }
        else {
          setShowToast(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const openLocker = () => {
    const formData = new FormData();
    formData.append('objCode', objCode);
    api.post(`api/partner/orders/storeOrder`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
      }
    })
      .then(function (response) {
        console.log(response);
        if (response.data.ResultCode === 1) {
          setStep(5);
          setShowToast(false);
          setPhoneNumber('')
          setObjCode('')
          setDeltaRef('')
          setStaffCode('')
          setTimeout(() => {
            setStep(1);
            dispatch(changeLanguage('pt-PT'))
          }, 3000)
        }
        else {
          setShowToast(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const isPhoneNumberValid = () => {
    const pattern = /\d{9}/;

    if (phoneNumber.match(pattern)) return true;
    return false;
  };

  const handleChangeObjCode = (e) => {
    setObjCode(e.target.value);
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleChangeRef = (e) => {
    setDeltaRef(e.target.value);
  };

  const handleChangeStaffCode = (e) => {
    setStaffCode(e.target.value);
  };

  useEffect(() => {
    const timeOutToast = setTimeout(() => {
      setShowToast(false)
    }, [3000])
    return () => clearTimeout(timeOutToast);
  }, [, showToast])

  if (step === 1) return (
    <>
      <div className='position-absolute m-0 p-0 px-2' style={{ height: 35, width: "calc(100% - 1.6rem)", top: 20 }}>
        <div className='position-relative w-100 h-100 m-0 p-0'>
          <CustomToast showToast={showToast} setShowToast={setShowToast} error />
        </div>
      </div>
      <div className="px-2 d-flex flex-column justify-content-center align-content-center w-100 h-100 m-0">
        <span style={{ fontSize: "1.6em" }} className="my-2"><FormattedMessage id="app.depositOrder" /></span>
        <span className="my-2 mx-1" style={{ fontSize: "1.1em" }}><FormattedMessage id="app.insertOrderData" /></span>
        <input
          type="text"
          className="w-100 mt-3 mb-2 input"
          style={{ padding: 12 }}
          inputMode="numeric"
          placeholder={`${intl.formatMessage({ id: 'app.staffCode' })}`}
          value={staffCode}
          onChange={handleChangeStaffCode}
        />
        <input
          type="text"
          className="w-100 my-2 input"
          style={{ padding: 12 }}
          placeholder={`${intl.formatMessage({ id: 'app.objectCode' })}`}
          value={objCode}
          onChange={handleChangeObjCode}
        />
        <input
          type="text"
          className="w-100 my-2 input"
          style={{ padding: 12 }}
          inputMode="numeric"
          placeholder={`${intl.formatMessage({ id: 'app.phone' })}`}
          value={phoneNumber}
          maxLength="9"
          onChange={handleChangePhoneNumber}
        />
        <input
          type={"text"}
          style={{ padding: 12 }}
          className="w-100 input my-2"
          placeholder={intl.formatMessage({ id: 'app.deltaPostRef' })}
          value={deltaRef}
          onChange={handleChangeRef}
        />
        <PrimaryButton
          text={intl.formatMessage({ id: 'app.confirm' })}
          onClick={() => setStep(2)}
          color="red"
          className="w-100 mt-2"
          isEnabled={objCode !== '' && phoneNumber !== '' && staffCode !== '' && isPhoneNumberValid()}
        />
      </div>
    </>
  );
  if (step === 2) return (
    <>
      <div className='position-absolute m-0 p-0 px-2' style={{ height: 35, width: "calc(100% - 1.5rem)", top: 20 }}>
        <div className='position-relative w-100 h-100 m-0 p-0'>
          <CustomToast showToast={showToast} setShowToast={setShowToast} error />
        </div>
      </div>
      <div className="px-2 d-flex flex-column justify-content-center align-content-center w-100 h-100 m-0">
        <span className="my-2 mx-1" style={{ fontSize: "1.2em" }}><FormattedMessage id="app.confirmOrderData" /></span>
        <div className='m-0 mt-3 mb-2' style={{ padding: 12, borderRadius: "6px" }}>
          <span className='m-0 p-0' style={{ fontSize: "0.9em", fontWeight: 600 }}>
            <FormattedMessage id="app.staffCode" /><span style={{ fontWeight: 400 }} className='m-0 p-0'>: {staffCode}</span>
          </span>
        </div>
        <div className='m-0 my' style={{ padding: 12, borderRadius: "6px" }}>
          <span className='m-0 p-0' style={{ fontSize: "0.9em", fontWeight: 600 }}>
            <FormattedMessage id="app.objectCode" /><span style={{ fontWeight: 400 }} className='m-0 p-0'>: {objCode}</span>
          </span>
        </div>
        <div className='m-0  my-2' style={{ padding: 12, borderRadius: "6px" }}>
          <span className='m-0 p-0' style={{ fontSize: "0.9em", fontWeight: 600 }}>
            <FormattedMessage id="app.phone" /><span style={{ fontWeight: 400 }} className='m-0 p-0'>: {phoneNumber}</span>
          </span>
        </div>
        {
          (deltaRef !== '' && deltaRef) &&
          <div className='m-0  mt-2 mb-2' style={{ padding: 12, borderRadius: "6px" }}>
            <span className='m-0 p-0' style={{ fontSize: "0.9em", fontWeight: 600 }}>
              <FormattedMessage id="app.deltaPostRef" /><span style={{ fontWeight: 400 }} className='m-0 p-0'>: {deltaRef}</span>
            </span>
          </div>
        }
        <PrimaryButton text={intl.formatMessage({ id: 'app.back' })} onClick={() => setStep(1)} color="white" className="w-100 mt-2 mb-2" />
        <PrimaryButton
          text={intl.formatMessage({ id: 'app.confirm' })}
          onClick={createOrder}
          color="red"
          className="w-100 mt-2"
          isEnabled={objCode !== '' && phoneNumber !== '' && isPhoneNumberValid()}
        />
      </div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_CAPTCHA_KEY}
        ref={recaptchaRef}
        size="invisible"
        style={{ display: "none" }}
      />
    </>
  );
  else if (step === 3) return (
    <>
      <div className='position-absolute m-0 p-0 px-2' style={{ height: 32.5, width: "calc(100% - 1.8rem)", top: 20 }}>
        <div className='position-relative w-100 h-100 m-0 p-0'>
          <CustomToast showToast={showToast} setShowToast={setShowToast} error />
        </div>
      </div>
      <div className="px-2 d-flex flex-column justify-content-center align-content-center w-100 h-100 m-0">
        <span style={{ fontSize: "1.6em" }} className="my-2"><FormattedMessage id="app.depositOrder" /></span>
        <span className="mt-2 mb-3 mx-1" style={{ fontSize: "1.1em" }}><FormattedMessage id="app.chooseLockerSize" /></span>
        <div className='d-flex flex-column m-0 p-0 w-100 h-auto'>
          {
            availableSizes && availableSizes.map((item, index) => {
              return (
                <div key={index} className='m-0 p-0 w-100 d-flex' onClick={() => chooseLockerSize(item)}>
                  <span className={`p-3 ${index === availableSizes?.length - 1 ? "mt-2" : "my-2"} m-0 p-0 w-100 text-start`}
                    style={{ fontSize: "1em", background: colors.white, borderRadius: "6px" }}>
                    <FormattedMessage id="app.size" /> {item}
                  </span>
                </div>
              );
            })
          }
        </div>
      </div>
    </>
  );

  else if (step === 4) return (
    <>
      <div className='position-absolute m-0 p-0 px-2' style={{ height: 32.5, width: "calc(100% - 1.8rem)", top: 20 }}>
        <div className='position-relative w-100 h-100 m-0 p-0'>
          <CustomToast showToast={showToast} setShowToast={setShowToast} error />
        </div>
      </div>
      <div className="px-2 d-flex flex-column justify-content-center align-content-center w-100 h-100 m-0">
        <LockerInfo text="app.depositOrderInLocker" locker={locker} />
        <PrimaryButton text={intl.formatMessage({ id: 'app.back' })} onClick={() => setStep(3)} color="white" className="w-100 mt-2 mb-2" />
        <PrimaryButton text={intl.formatMessage({ id: 'app.openLocker' })} onClick={openLocker} color="red" className="w-100 mt-2" />
      </div>
    </>
  );
  else return (
    <div className="px-2 d-flex flex-column justify-content-center align-content-center w-100 h-100 m-0">
      <LockerInfo text="app.lockerOpen" locker={locker} />
    </div>
  );
};