import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppLayout } from 'layouts/AppLayout';

import { Deposit } from 'views/deposit';

export const AppRouter = () => {

  return (
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route path="/" element={<Deposit />} />
        </Routes>
      </AppLayout>
    </BrowserRouter>
  );
};